<template>
  <v-form class="w-50">
    <v-fade-transition class="pa-0">
      <v-list v-if="userAddedTickets.length >= 1" class="transparent px-0">
        <v-list-item
          v-for="(ticket, key) in userAddedTickets"
          :key="ticket.id"
          class="px-0"
        >
          <v-list-item-content>
            <v-list-item-title class="white--text">
              {{ key + 1 }}. {{ ticket.filename }}
            </v-list-item-title>
          </v-list-item-content>
          <v-toolbar
            class="file-select-toolbar transparent elevation-0 flex-grow-0 px-0 text-right"
          >
            <v-spacer></v-spacer>
            <span class="white--text">ID:&nbsp;{{ ticket.salesId }}</span>
            <v-btn icon color="error" @click="removeUserAddedTicket(ticket.id)">
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-list-item>
      </v-list>
    </v-fade-transition>
    <v-fade-transition>
      <label class="file-select">
        <v-card color="white" class="mb-5 ripple" height="200px" ripple>
          <v-layout align-center justify-center fill-height>
            <input
              ref="files"
              type="file"
              multiple
              accept="application/pdf"
              @change="didPickFiles($event.target.files)"
            />
            <v-progress-circular
              v-show="loading.state && loading.type === 'uploadFile'"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-icon v-show="!loading.state">mdi-cloud-upload</v-icon>
          </v-layout>
        </v-card>
      </label>
    </v-fade-transition>
    <!-- <v-list class="transparent">
      <draggable :list="files" :disabled="files.length == 1" @update="onUpdate">
        <v-list-item v-for="(ticket, key) in tickets" :key="ticket.ticketId">
          <v-list-item-content>
            <v-list-item-title class="white--text">
              {{ key + 1 }}. {{ ticket.filename }} {{ ticket.salesId }}
            </v-list-item-title>
          </v-list-item-content>
          <v-toolbar class="transparent elevation-0 text-right">
            <v-spacer></v-spacer>
            <v-btn icon color="error" @click="files.splice(key, 1)">
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
            <v-icon v-if="files.length > 1" small style="cursor: move;">
              mdi-drag
            </v-icon>
          </v-toolbar>
        </v-list-item>
      </draggable>
    </v-list> -->
  </v-form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'FileSelect',

  props: {
    value: Array
  },
  /*
    Defines the data used by the component
  */
  data() {
    return {
      files: []
    }
  },
  computed: {
    ...mapGetters('app', ['loading']),
    ...mapGetters('events', ['userAddedTickets'])
  },

  /*
    Defines the method used by the component
  */
  methods: {
    ...mapMutations('events', ['removeUserAddedTicket']),
    // If files dragged
    onUpdate() {
      // console.log(this.files)
      // this.$emit('input', this.files)
    },

    // If files selected directly
    didPickFiles(files) {
      const arrayOfFiles = Array.from(files)

      // Adds the uploaded file to the files array
      arrayOfFiles.forEach(async file => {
        await this.$store.dispatch('events/uploadFile', {
          fileName: file.name,
          data: this.getFormData(file)
        })
      })
    },

    /*
      Convert File to FormData
    */
    getFormData(file) {
      const formData = new FormData()
      formData.append(file.name, file)
      return formData
    },

    /*
      Gets the preview image for the file.
    */
    getImagePreviews() {
      /*
        Iterate over all of the files and generate an image preview for each one.
      */
      this.files.forEach((file, index) => {
        /*
          Ensure the file is an image file
        */
        if (/\.(pdf)$/i.test(file.name)) {
          /*
            Create a new FileReader object
          */
          const reader = new FileReader()

          /*
            Add an event listener for when the file has been loaded
            to update the src on the file preview.
          */
          reader.addEventListener(
            'load',
            () => {
              this.$refs[`image${index}`][0].src = reader.result
            },
            false
          )

          /*
            Read the data for the file in through the reader. When it has
            been loaded, we listen to the event propagated and set the image
            src to what was loaded from the reader.
          */
          reader.readAsDataURL(file)
        }
      })
    }
  }
}
</script>

<style scoped>
.file-select input[type='file'] {
  opacity: 0;
  /* width: 100%;
  height: 200px; */
  position: absolute;
  cursor: pointer;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.preview {
  object-fit: cover !important;
}

>>> .v-toolbar__content {
  padding-right: 0 !important;
}
</style>
